import React, { useState, useEffect, useContext, useCallback } from "react";
import { debounce } from "lodash"; // Import debounce from lodash
import AuthContext from "../AuthContext";

function Inventory() {
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [updateProduct, setUpdateProduct] = useState([]);
  const [products, setAllProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [updatePage, setUpdatePage] = useState(true);
  const [allStores, setAllStores] = useState([]);
  const [storeID, setStoreID] = useState("");
  console.log(products);
  
  const authContext = useContext(AuthContext);

  const fetchStoresData = () => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/store/get`)
      .then((response) => response.json())
      .then((data) => {
        setAllStores(data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchStockData();
  }, [storeID]);

  useEffect(() => {
    fetchStoresData();
  }, []);

  // Fetching Data of All Products
  const fetchStockData = () => {
    if(storeID){
      fetch(`${process.env.REACT_APP_BACKEND_URL}/api/store/get/stock/${storeID}`)
        .then((response) => response.json())
        .then((data) => {
          setAllProducts(data);
        })
        .catch((err) => console.log(err));
    }
  };

  const fetchSearchData = useCallback(()=>{
    if(!storeID) return
    debounce((term) => {
      if (!term) {
        fetchStockData(); // If the search term is empty, fetch all products
        return;
      }
      fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/store/search?searchTerm=${encodeURIComponent(term)}&storeID=${encodeURIComponent(storeID)}`)
        .then((response) => response.json())
        .then((data) => {
          setAllProducts(data);
        })
        .catch((err) => console.log(err));
    }, 300)}, // Wait for 300ms after the user stops typing
    [storeID] // Dependency array should include searchTerm if you want to fetch new data on searchTerm change
);

  // Handle Search Term
  const handleSearchTerm = (e) => {
    setSearchTerm(e.target.value);
    fetchSearchData(e.target.value);
  };

  if (!allStores) return;

  return (
    <div className="col-span-12 lg:col-span-10  flex justify-center">
      <div className=" flex flex-col gap-5 w-11/12">
        {/* Table  */}
        <div className="overflow-x-auto rounded-lg border bg-white border-gray-200 ">
          <div className="flex flex-col justify-between pt-5 pb-3 px-3">
            <div className="flex gap-4 justify-between items-center ">
              <span className="font-bold">Inventory</span>
              <div className="flex justify-center items-center px-2 border-2 rounded-md ">
                <img
                  alt="search-icon"
                  className="w-5 h-5"
                  src={require("../assets/search-icon.png")}
                />
                <input
                  className="border-none outline-none focus:border-none text-xs"
                  type="text"
                  placeholder="Search here"
                  value={searchTerm}
                  onChange={handleSearchTerm}
                />
              </div>
            </div>
            <div className="flex w-full mt-2 px-5">
              <select
                onChange={(e) => setStoreID(e.target.value)}
                value={storeID}
                className="w-full lg:w-1/2"
              >
                <option value={""}>-- Select Store --</option>
                {allStores.map((itm , idx) => (
                  <option key={idx} value={itm._id}>
                    {itm.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <table className="min-w-full divide-y-2 divide-gray-200 text-sm">
            <thead>
              <tr>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Image
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Product
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Available Stock
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Onshelf Stock
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Total Damaged
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Total Needs Repair
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Total Issuance
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {products.map((element) => (
                <tr key={element._id}>
                  <td className="whitespace-nowrap px-4 py-2">
                    {element.image ? (
                      <img
                        src={element.image}
                        alt="Uploaded"
                        className="w-24 h-24 object-cover"
                      />
                    ) : (
                      <span>No image</span>
                    )}
                  </td>
                  <td className="whitespace-nowrap px-4 py-2  text-gray-900">
                    {element.name}
                  </td>
                  <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                    {element.available}
                  </td>
                  <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                    {element.onshelf}
                  </td>
                  <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                    {element.damaged}
                  </td>
                  <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                    {element.needsRepair}
                  </td>
                  <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                    {element.issuance}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Inventory;
